import React from "react";
import { RightSubContent } from "./RightSubContent";
import { ReactComponent as InfoIcon } from "assets/svgs/info-circle.svg";
import { ReactComponent as InfoIconDisabled } from "assets/svgs/info-circle-disabled.svg";
import { Link } from "react-router-dom";
import { ConnectedAccountItem } from "./ConnectedAccountItem";
import { CustomTooltip, RightContentDiv } from "./RightContent.styles";

export function RightContent({ gosprout_settings, connectedAccounts }) {
  const disabled = !gosprout_settings?.gosprout_enabled;

  const connectedAccountsMap = connectedAccounts.reduce((acc, obj) => {
    acc[obj.gosprout_account_type] = obj.count;
    return acc;
  }, {});

  return (
    <RightContentDiv>
      <RightSubContent
        gosprout_settings={gosprout_settings}
        title="Connected Accounts"
        titleIcon={
          disabled ? (
            <InfoIconDisabled />
          ) : (
            <CustomTooltip
              title="An overview of Rialto accounts connected to GoSprout."
              placement="top"
              arrow
            >
              <InfoIcon />
            </CustomTooltip>
          )
        }
        itemList={[
          {
            itemNode: (
              <ConnectedAccountItem
                title="Sponsors"
                actualCount={0}
                totalCount={connectedAccountsMap["Sponsors"] ?? 0}
                disabled={disabled}
              />
            ),
          },
          {
            itemNode: (
              <ConnectedAccountItem
                title="Employers"
                actualCount={0}
                totalCount={connectedAccountsMap["Employers"] ?? 0}
                disabled={disabled}
              />
            ),
          },
          {
            itemNode: (
              <ConnectedAccountItem
                title="Apprentice"
                actualCount={0}
                totalCount={connectedAccountsMap["Apprentice"] ?? 0}
                disabled={disabled}
              />
            ),
          },
          {
            itemNode: (
              <ConnectedAccountItem
                title="RTI Provider"
                actualCount={0}
                totalCount={connectedAccountsMap["RTI Provider"] ?? 0}
                disabled={disabled}
              />
            ),
          },
          {
            itemNode: (
              <ConnectedAccountItem
                title="Journeyworker"
                actualCount={0}
                totalCount={connectedAccountsMap["Journeyworker"] ?? 0}
                disabled={disabled}
              />
            ),
          },
        ]}
      />
      <RightSubContent
        gosprout_settings={gosprout_settings}
        title="Helpdesk Article"
        itemList={[
          {
            itemNode: (
              <Link
                to="https://help.rialtoapp.com/en/articles/9977098-setting-gosprout-integration"
                data-disabled={disabled}
              >
                GoSprout Integration
              </Link>
            ),
          },
          {
            itemNode: (
              <Link
                to="https://help.rialtoapp.com/en/articles/9938007-managing-member-groups"
                data-disabled={disabled}
              >
                Create Member Groups on Rialto
              </Link>
            ),
          },
        ]}
      />
    </RightContentDiv>
  );
}
