import { api } from "api/api";

export async function postGoSproutIntegration({ neighborhood_id, body }) {
  const { data } = await api.post(
    `/neighborhoods/${neighborhood_id}/member_groups/goSprout-account-types/connect`,
    {
      data: body,
    }
  );
  return data;
}
