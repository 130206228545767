import React from "react";
import { DropdownBox } from "./IntegrationsAccountTypeForm.styles";

export const SelectFieldDropdown = ({ children, style, ...props }) => {
  return (
    <DropdownBox style={style} {...props}>
      {children}
    </DropdownBox>
  );
};
