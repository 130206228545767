import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useWindowSize } from "hooks/useWindowSize";
import { Skeleton } from "@material-ui/lab";
import cribAPI from "../../api/crib";
import sessionInfo from "utilities/session/sessionInfo";
import Twitter from "assets/svgs/twitter";
import Instagram from "assets/svgs/instagram";
import LinkedinOutline from "assets/svgs/linkedInOutline";
import OfficeHoursIcon from "assets/svgs/officeHours";
import Compass from "assets/svgs/compass";
import UserImageAvatar from "components/GlobalComponents/UserImageAvatar";
import "./CribBanner.css";

const cribSocials = [
  {
    icon: <LinkedinOutline />,
    name: "linkedin",
    protocol: "https://www.linkedin.com/in/",
  },
  {
    icon: <Twitter />,
    name: "twitter",
    protocol: "https://www.x.com/",
  },
  {
    icon: <Instagram />,
    name: "instagram",
    protocol: "https://www.instagram.com/",
  },
];

const cribNavigation = (crib_id, customRoute, role, isCrib, isMobile) => {
  const isBCO = role === "BCO";
  return [
    {
      path: customRoute
        ? `/${customRoute}/crib/${crib_id}`
        : `/crib/${crib_id}`,
      name: "Profile",
      visible: !isMobile,
    },
    {
      icon: isMobile && <Compass />,
      path: customRoute
        ? `/${customRoute}/crib/${crib_id}/app-hub`
        : `/crib/${crib_id}/app-hub`,
      name: "App Hub",
      visible: isCrib,
    },
    // {
    //   icon: isMobile && <OfficeHoursIcon />,
    //   path: `/crib/${crib_id}/officehours`,
    //   name: "Office Hours",
    //   visible: isBCO && isCrib,
    // },
  ];
};

const menus = (links) => {
  return links.map(({ icon, name, path, visible }) =>
    visible ? (
      <li key={name} className="crib-banner__navigation--item">
        {icon && icon}
        <NavLink
          to={path}
          className={({ isActive }) =>
            `crib-banner__navigation--link ${isActive && "active"}`
          }
          end
        >
          {name}
        </NavLink>
      </li>
    ) : null
  );
};

const getURLHost = (url) => {
  return new URL(`https://${url}`).host;
};

const CribBanner = () => {
  const { width } = useWindowSize();
  const { crib_id, customRoute } = useParams();
  const session = sessionInfo();

  const {
    isLoading,
    isRefetching,
    data: cribProfileData,
  } = useQuery({
    retry: 1,
    refetchOnWindowFocus: false,
    queryKey: "cribProfile",
    queryFn: () => cribAPI.getCribProfile(crib_id),
  });

  const { crib, crib_editable } = cribProfileData?.data || {};

  const isCrib = crib_editable;

  return (
    <div className="crib-banner">
      <div className="crib-banner__container">
        <div className="crib-banner__box">
          {isLoading || isRefetching ? (
            <div className="crib-banner__user">
              <Skeleton
                variant="circle"
                animation="pulse"
                className="crib-banner__user--img"
              />
              <div className="crib-banner__user--info">
                <Skeleton
                  variant="rect"
                  animation="pulse"
                  style={{
                    height: "18px",
                    borderRadius: "4px",
                    width: "11em",
                  }}
                />
                <Skeleton
                  variant="rect"
                  animation="pulse"
                  style={{
                    margin: "10px 0",
                    height: "16px",
                    borderRadius: "4px",
                    width: "7em",
                  }}
                />
                <Skeleton
                  variant="rect"
                  animation="pulse"
                  style={{
                    height: "12px",
                    borderRadius: "4px",
                    width: "5em",
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="crib-banner__user">
              <UserImageAvatar
                s3_picture_url={crib?.profile_picture}
                Alt={`${crib?.crib_first_name} avatar`}
                className="crib-banner__user--img"
              />
              <div className="crib-banner__user--info">
                <h2 className="crib-banner__user--name">
                  {crib?.crib_first_name} {crib?.crib_last_name}
                </h2>
                <p className="crib-banner__user--job">{crib?.job_title}</p>
                <p className="crib-banner__company">{crib?.workplace}</p>
              </div>
            </div>
          )}
          <div className="crib-banner__socials--container">
            {crib?.website_link && (
              <a
                href={`https://${crib?.website_link}`}
                className="crib-banner__website"
                target="_blank"
                rel="noreferrer"
              >
                {getURLHost(crib?.website_link)}
              </a>
            )}
            <div className="crib-banner__socials">
              {cribSocials?.map(({ name, icon, protocol }) => {
                if (crib?.[name]) {
                  return (
                    <a
                      key={name}
                      href={`${protocol}${crib?.[name]}`}
                      className="crib-banner__social--link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {icon}
                    </a>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="crib-banner__navigation">
          <ul className="crib-banner__navigation--list">
            {isLoading || isRefetching ? (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rect"
                    animation="pulse"
                    style={{
                      height: "13px",
                      borderRadius: "4px",
                      width: "6em",
                      marginTop: "4px",
                      marginBottom: "12px",
                      marginRight: "24px",
                    }}
                  />
                ))}
              </>
            ) : (
              menus(
                cribNavigation(
                  crib_id,
                  customRoute,
                  session.role,
                  isCrib,
                  width <= 767
                )
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CribBanner;
