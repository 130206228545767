import React from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { clsx } from "clsx";
import cribAPI from "api/crib";
import ReactQuill from "react-quill";
import UserImageAvatar from "components/GlobalComponents/UserImageAvatar";
import routeToActiveSessionCrib from "utilities/session/routeToActiveSessionCrib";
import isvalidCribId from "utilities/session/isvalidCribId";
import "./CribProfile.css";

const CribProfile = () => {
  let { crib_id } = useParams();

  // re-route invalid crib page
  if (!isvalidCribId(crib_id)) {
    routeToActiveSessionCrib();
  }

  const {
    isLoading,
    isRefetching,
    data: cribData,
  } = useQuery({
    retry: 1,
    refetchOnWindowFocus: false,
    queryKey: "cribProfile",
    queryFn: () => cribAPI.getCribProfile(crib_id),
  });

  const { crib, crib_editable, neighborhoods } = cribData?.data || {};

  return (
    <div className="crib" data-testid="crib-profile">
      <div className="crib__container">
        <div className="crib__box">
          <div className="crib__header">
            <h2 className="crib__heading">Summary</h2>
            {!isLoading && crib_editable ? (
              <Link to={`/crib/${crib_id}/edit`} className="crib__link">
                Edit
              </Link>
            ) : null}
          </div>
          {isLoading || isRefetching ? (
            <Skeleton
              variant="rect"
              animation="pulse"
              style={{ width: "100%", height: "4.5em", borderRadius: "8px" }}
            />
          ) : (
            <ReactQuill
              className="crib__summary--text"
              readOnly={true}
              theme={"bubble"}
              value={crib?.crib_summary || ""}
            />
          )}
        </div>
        <div className="crib__wrapper">
          <div className="crib__box">
            {isLoading || isRefetching ? (
              <h2 className="crib__heading">Neighborhoods</h2>
            ) : (
              <div className="crib__header">
                <h2 className="crib__heading">
                  Neighborhoods ({neighborhoods?.length})
                </h2>
                <Link
                  to={`/crib/${crib_id}/neighborhoods`}
                  className="crib__link"
                >
                  See all
                </Link>
              </div>
            )}
            <div className="crib__neighborhood">
              {isLoading || isRefetching
                ? Array.from(new Array(2)).map((_, index) => (
                    <div key={index} className="crib__neighborhood--box">
                      <Skeleton
                        variant="circle"
                        animation="pulse"
                        className="crib__neighborhood--image"
                        style={{
                          border: "none",
                          display: "block",
                          margin: "12px auto 0",
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="pulse"
                        style={{
                          margin: "5px auto 0",
                          borderRadius: "4px",
                          height: "12px",
                          width: "150px",
                        }}
                      />
                      <Skeleton
                        variant="rect"
                        animation="pulse"
                        style={{
                          margin: "5px auto 0",
                          borderRadius: "4px",
                          height: "12px",
                          width: "100px",
                        }}
                      />
                    </div>
                  ))
                : neighborhoods
                    ?.slice(0, 4)
                    ?.map(
                      ({
                        neighborhood_id,
                        link_clickable,
                        neighborhood_name,
                        profile_picture,
                        neighborhood_description,
                      }) => (
                        <div
                          className="crib__neighborhood--box"
                          key={neighborhood_id}
                        >
                          <UserImageAvatar
                            s3_picture_url={profile_picture}
                            Alt={`${neighborhood_name} logo`}
                            className={clsx(
                              "crib__neighborhood--image",
                              !link_clickable && "neighborhood-disabled"
                            )}
                          />
                          <div className="crib__neighborhood--info">
                            {link_clickable ? (
                              <Link
                                to={`/neighborhood/${neighborhood_id}`}
                                className="crib__neighborhood--name"
                              >
                                {neighborhood_name}
                              </Link>
                            ) : (
                              <h3
                                className={clsx(
                                  "crib__neighborhood--name neighborhood-disabled"
                                )}
                              >
                                {neighborhood_name}
                              </h3>
                            )}
                            <p
                              className={clsx(
                                "crib__neighborhood--description",
                                !link_clickable && "neighborhood-disabled"
                              )}
                            >
                              {neighborhood_description}
                            </p>
                          </div>
                        </div>
                      )
                    )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CribProfile;
