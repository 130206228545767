import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const Overlay = styled.div`
  /* Layout Properties */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 9991;
  background: rgba(211, 211, 211, 0.5) 0 0 no-repeat padding-box;
  opacity: 1;

  @media screen and (max-width: 480px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 100px);
  }
`;

export const Body = styled.div`
  &[data-fullscreen="true"] {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  column-gap: 8px;

  @media screen and (max-width: 480px) {
    margin-bottom: 12px;
  }

  .close-icon {
    cursor: pointer;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  .back-button {
    display: none;
    @media screen and (max-width: 480px) {
      all: unset;
      display: block;
      color: ${COLORS.green.grn1};
      font-size: 0.938rem;
      cursor: pointer;
      margin-bottom: 12px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 1.75rem;
  color: ${COLORS.purple.p500};

  @media screen and (max-width: 480px) {
    font-size: 1.25rem;
  }
`;
