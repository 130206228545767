import React from "react";
import { LeftContentHeaderDiv } from "./LeftContent.styles";

import { useMutation, useQueryClient } from "react-query";
import { patchEnableFields } from "api/integrations/patchEnableFields";
import { useParams } from "react-router-dom";
import store from "utilities/storage";
import { ToggleAccountSwitch } from "./ToggleAccountSwitch";

export function LeftContentHeader({
  logo,
  title,
  description,
  gosprout_settings,
}) {
  const queryClient = useQueryClient();

  const { neighborhood_id } = useParams();

  const _neighborhood_id = store.retrieve("neighborhood_id");
  const possibleNeighbohoodId = neighborhood_id ?? _neighborhood_id;

  const mutate = useMutation({
    mutationFn: patchEnableFields,
    onSuccess: () =>
      queryClient.invalidateQueries([
        "gosprout-settings",
        possibleNeighbohoodId,
      ]),
  });
  return (
    <LeftContentHeaderDiv>
      {logo}
      <div className="right">
        <div className="rightTop">
          <h3>{title}</h3>
          <div className="switch-section">
            <ToggleAccountSwitch
              gosprout_enabled={gosprout_settings?.gosprout_enabled}
              onChange={(val) =>
                mutate.mutate({
                  neighborhood_id: possibleNeighbohoodId,
                  gosprout_enabled: val.target.checked,
                })
              }
            />
          </div>
        </div>
        <div className="rightBottom">
          <p>{description}</p>
        </div>
      </div>
    </LeftContentHeaderDiv>
  );
}
