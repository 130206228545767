import { GlobalModal } from "components/GlobalModal/GlobalModal";
import { GlobalModalHeader } from "components/GlobalModal/GlobalModalHeader";
import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";
import { hideScroll } from "styles/global.styles";

export const StyledModal = styled(GlobalModal)`
  padding-top: 20px;
  border-radius: 24px;
  border: 0.25px solid ${COLORS.purple.p500};
  background: ${COLORS.gray.gr6};
  width: min(984px, 90vw);
  height: min(770px, 90vh);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    width: 100%;
    padding-top: 12px;
    background: #fff;
    border-radius: unset;
    border: unset;
    height: 100%;
  }

  .title {
    all: unset;
    color: ${COLORS.green.grn1};
    font-size: 1rem;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 0.938rem;
    }
  }
`;

export const StyledGlobalModalHeader = styled(GlobalModalHeader)`
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (max-width: 480px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const StyledModalBody = styled.div`
  display: grid;
  grid-template-columns: 3.5fr auto 1fr;
  flex: 1;
  overflow: auto;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .vertical-divider {
      display: none;
    }

    ${hideScroll}
  }
`;

export const StyledModalLeft = styled.div`
  padding: 24px 24px 40px 24px;
  overflow-y: auto;

  @media screen and (max-width: 480px) {
    padding: 12px 16px 0px 16px;
    overflow-y: unset;
  }

  .gosprout-logo {
    color: ${COLORS.neutral.n3};

    &[data-plain="true"] {
      color: transparent;
    }
  }
`;

export const StyledModalRight = styled.div`
  padding: 24px;
  overflow-y: auto;

  @media screen and (max-width: 480px) {
    padding: 16px 16px 40px 16px;
    padding-top: 0;
    overflow-y: unset;
  }
`;
