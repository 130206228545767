import React from "react";

import { Skeleton } from "@material-ui/lab";
import { RightContentDiv } from "./RightContent.styles";
import { RightSubContent } from "./RightSubContent";

export function RightContentLoading() {
  return (
    <RightContentDiv>
      <RightSubContent
        title="Connected Accounts"
        itemList={Array(5)
          .fill(0)
          .map((_, index) => ({
            itemNode: (
              <Skeleton
                key={index}
                style={{
                  height: "18px",
                  width: "100%",
                  borderRadius: "4px",
                }}
              />
            ),
          }))}
      />
      <RightSubContent
        title="Helpdesk Article"
        itemList={Array(2)
          .fill(0)
          .map((_, index) => ({
            itemNode: (
              <Skeleton
                key={index}
                style={{
                  height: "18px",
                  width: "100%",
                  borderRadius: "4px",
                }}
              />
            ),
          }))}
      />
    </RightContentDiv>
  );
}
