import React from "react";
import {
  IntegrationsCardDiv,
  IntegrationsCardBottomDiv,
} from "./IntegrationsCard.styles";

export function IntegrationsCard({ logo, title, description, ...rest }) {
  return (
    <IntegrationsCardDiv {...rest}>
      {logo}
      <IntegrationsCardBottomDiv>
        <h3>{title}</h3>
        <p>{description}</p>
      </IntegrationsCardBottomDiv>
    </IntegrationsCardDiv>
  );
}
