import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Containers from "./containers";
import sessionStatus from "utilities/session/sessionStatus";
import "./App.css";

const App = () => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  // TODO: Use semantic versioning standard for rialto app versioning
  // see: https://semver.org/

  console.info("APP VERSION: 1.1.2");

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <BrowserRouter>
        <Containers />
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
