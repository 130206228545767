import { createGlobalStyle, css } from "styled-components";

export const hideScroll = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const GlobalStyles = createGlobalStyle`
  .invisible-scroll {
    ${hideScroll}
  }

  .cursor-pointer{
    cursor: pointer;
  }

  .unset-all{
    all: unset
  }

  .new-icon{
    span{
      display: inline-flex
    }
  }
`;

export { GlobalStyles };
