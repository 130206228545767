import store from "../storage.js";

const sessionInfo = () => {
  const l_Storage = window.localStorage;
  const privileges = l_Storage.getItem("neighborhood_privileges");
  return {
    token: store.retrieve("ShRSessToken"),
    firstname: store.retrieve("firstname"),
    lastname: store.retrieve("lastname"),
    jobtitle: store.retrieve("job_title"),
    twitter: store.retrieve("twitter"),
    role: store.retrieve("role"), // ENT, BCO, SME, RialtoAdmin
    account_id: store.retrieve("account_id"),
    crib_created: store.retrieve("crib_created"),
    neighborhood_id: store.retrieve("neighborhood_id"),
    neighborhood_created: store.retrieve("neighborhood_created"),
    profile_picture: store.retrieve("profile_picture"),
    initials: store.retrieve("initials"),
    total_notifications: store.retrieve("total_notifications"),
    my_neighborhood_access_rights: JSON.parse(privileges || "{}"),
    login_time: store.retrieve("login_time"),
    region: store.retrieve("region"),
    years_of_experience: store.retrieve("years_of_experience"),
    size_of_clientele: store.retrieve("size_of_clientele"),
    timezone_id: store.retrieve("timezone_id"),
    endOfSequence: JSON.parse(store.retrieve("endOfSequence") || false)
  };
};

/**
 * 
 * SCHEMA
 *  
 *  my_neighborhood_access_rights
    [
        {"crib_id":919201928,"access_level":3,"neighborhood_id":919201928},
        {"crib_id":919201928,"access_level":3,"neighborhood_id":63259434},
        {"crib_id":919201928,"access_level":3,"neighborhood_id":51122919}
    ]
 */

export default sessionInfo;
