import React from "react";

import { Skeleton } from "@material-ui/lab";
import {
  AccountTypeFormButton,
  AccountTypeFormWrapper,
} from "./IntegrationsAccountTypeForm.styles";

export function IntegrationsAccountTypeFormLoading() {
  return (
    <>
      <AccountTypeFormWrapper>
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              key={index}
              style={{
                height: "66px",
                width: "100%",
                borderRadius: "12px",
              }}
            />
          ))}
      </AccountTypeFormWrapper>
      <AccountTypeFormButton size="small" kind="secondary" disabled>
        Done
      </AccountTypeFormButton>
    </>
  );
}
