import { GlobalModal } from "components/GlobalModal/GlobalModal";
import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const StyledModal = styled(GlobalModal)`
  padding: 24px;
  border-radius: 24px;
  border: 0.25px solid ${COLORS.purple.p500};
  background: ${COLORS.gray.gr6};
  width: min(984px, 90vw);
  height: min(770px, 90vh);

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 12px 16px 16px 16px;
    background: ${COLORS.neutral.n0};
    border-radius: unset;
    border: unset;
    height: 100%;
  }

  .logo {
    color: transparent;
  }
`;

export const LearnMore = styled.div`
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  margin-top: 36px;
  padding: 2px 6px;
  width: fit-content;
  transition: background-color 250ms ease-out;

  :hover {
    border-radius: 8px;
    background-color: ${COLORS.neutral.n200};
  }

  @media screen and (max-width: 480px) {
    margin-top: 24px;
    padding: 0;
  }

  a {
    color: ${COLORS.neutral.n7};
    font-size: 1rem;

    @media screen and (max-width: 480px) {
      font-size: 0.875rem;
    }
  }
`;
