import React from "react";
import { ConnectedAccountItemText } from "./RightContent.styles";

export function ConnectedAccountItem({
  title,
  actualCount,
  totalCount,
  disabled,
}) {
  return (
    <ConnectedAccountItemText data-disabled={disabled}>
      {title} - {actualCount}/{totalCount}
    </ConnectedAccountItemText>
  );
}
