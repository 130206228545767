import React from "react";
import {
  StyledModalBody,
  StyledModalLeft,
  StyledModalRight,
} from "./IntegrationsAccountTypesDialog.styles";
import { LeftContent } from "./LeftContent/LeftContent";
import { RightContent } from "./RightContent/RightContent";
import { ReactComponent as Logo } from "assets/svgs/gosprout-img.svg";
import { Divider } from "@material-ui/core";

export function IntegrationsAccountTypesDialogRaw({
  gosprout_settings,
  onClose,
  connectedAccounts,
}) {
  const isMobile = window.innerWidth <= 480;
  return (
    <StyledModalBody>
      <StyledModalLeft className="invisible-scroll">
        <LeftContent
          logo={<Logo data-plain={isMobile} className="gosprout-logo" />}
          title="GoSprout"
          header_description="Connect your neighborhood members to your apprenticeships in GoSprout."
          gosprout_settings={gosprout_settings}
          onClose={onClose}
        />
      </StyledModalLeft>
      <Divider orientation="vertical" className="vertical-divider" />
      <StyledModalRight>
        <RightContent
          gosprout_settings={gosprout_settings}
          connectedAccounts={connectedAccounts}
        />
      </StyledModalRight>
    </StyledModalBody>
  );
}
