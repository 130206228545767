import { Switch } from "@material-ui/core";
import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const LeftContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 480px) {
    gap: 28px;
  }

  .bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;

    h3 {
      color: ${COLORS.neutral.n9};
      font-size: 1.25rem;
      font-weight: 700;

      @media screen and (max-width: 480px) {
        font-size: 1rem;
      }
    }

    p {
      color: ${COLORS.neutral.n8};
      font-size: 1rem;
      margin-top: 4px;

      @media screen and (max-width: 480px) {
        font-size: 0.875rem;
        margin-top: 2px;
      }
    }
  }
`;

export const LeftContentHeaderDiv = styled.div`
  display: flex;
  gap: 20px;

  @media screen and (max-width: 480px) {
    gap: 8px;
  }

  svg {
    width: 76px;
    height: 76px;

    @media screen and (max-width: 480px) {
      width: 48px;
      height: 48px;
    }
  }

  .right {
    flex: 1;
    .rightTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      h3 {
        color: ${COLORS.neutral.n9};
        font-size: 1.5rem;
        font-weight: 700;

        @media screen and (max-width: 480px) {
          font-size: 1.25rem;
        }
      }
      .switch-section {
        display: flex;
        align-items: center;
        gap: 12px;

        @media screen and (max-width: 480px) {
          gap: 8px;
        }

        p {
          color: ${COLORS.neutral.n8};
          font-size: 1rem;

          @media screen and (max-width: 480px) {
            font-size: 0.875rem;
          }
        }
      }
    }
    .rightBottom {
      p {
        color: ${COLORS.neutral.n8};
        font-size: 1rem;
        margin-top: 4px;

        @media screen and (max-width: 480px) {
          font-size: 0.875rem;
          margin-top: 4px;
        }
      }
    }
  }
`;

export const CustomSwitch = styled(Switch)`
  width: 39px;
  height: 24px;
  padding: 0px;

  @media screen and (max-width: 480px) {
    width: 32px;
    height: 19.862px;
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    box-shadow: unset;

    @media screen and (max-width: 480px) {
      width: 16px;
      height: 16px;
    }
  }
  & .MuiSwitch-track {
    border-radius: 12px;
    background-color: ${COLORS.neutral.n400};
    opacity: 1;
  }
  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 2px;
    transition-duration: 250ms;
    transition-timing-function: ease-out;
    &.Mui-checked {
      transform: translateX(15px);
      color: ${COLORS.neutral.n0};
      & + .MuiSwitch-track {
        background-color: ${COLORS.gold.go600};
        opacity: 1;
        border: 0;
      }

      @media screen and (max-width: 480px) {
        transform: translateX(12px);
      }
    }
  }
`;
