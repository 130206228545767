import React from "react";
import { RightSubContentDiv } from "./RightContent.styles";

export function RightSubContent({
  title,
  titleIcon,
  itemList = [],
  gosprout_settings,
}) {
  const disabled = !gosprout_settings?.gosprout_enabled;
  return (
    <RightSubContentDiv>
      <div className="titleNode">
        <h3 className="title" data-disabled={disabled}>
          {title}
        </h3>{" "}
        {titleIcon && titleIcon}
      </div>

      <ul className="list">
        {itemList.map((each, _idx) => (
          <li key={_idx} data-disabled={disabled}>
            {each.itemNode}
          </li>
        ))}
      </ul>
    </RightSubContentDiv>
  );
}
