import React from "react";

import { ReactComponent as ChevronUp } from "assets/svgs/select_arrow_up.svg";
import { ReactComponent as ChevronDown } from "assets/svgs/select_arrow_down.svg";
import { ReactComponent as CloseIcon } from "assets/svgs/close-circle.svg";

import * as testId from "utilities/testIds";
import {
  EndAdornment,
  PopperIcon,
  SelectFieldAutoComplete,
  SelectLabel,
  SelectTextField,
} from "./IntegrationsAccountTypeForm.styles";
import { SelectFieldDropdown } from "./SelectFieldDropdown";

export function SelectField({
  selectPlaceholder,
  form,
  formField,
  label,
  gosprout_settings,
  selectFieldTestId,
  field,
  ...rest
}) {
  const [openPopper, setOpenPopper] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const handleOpenPopper = (e) => {
    if (!gosprout_settings?.gosprout_enabled) {
      e.stopPropagation();
    } else {
      setOpenPopper(true);
    }
  };

  const handleClosePopper = (e) => {
    if (!gosprout_settings?.gosprout_enabled) {
      e.stopPropagation();
    } else {
      setOpenPopper(false);
    }
  };

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  const options = gosprout_settings?.member_groups || [];

  const disabled = !gosprout_settings?.gosprout_enabled;

  const selectedValues = form?.watch([
    "sponsor.member_group_id",
    "employer.member_group_id",
    "journey_worker.member_group_id",
    "rti_provider.member_group_id",
    "apprentice.member_group_id",
  ]);

  const selectedLabels = new Set(selectedValues.filter(Boolean));

  const getDropDownContent = (member_groups) => {
    if (options.length === 0) {
      return (
        <div className="empty">This Neighborhood has no member groups yet.</div>
      );
    } else {
      const isPart = options?.some((value) =>
        value?.member_group?.toLowerCase().includes(inputValue?.toLowerCase())
      );
      if (isPart) return member_groups;
      else
        return (
          <div className="empty">
            No results found. It seems you haven't created this member group yet
          </div>
        );
    }
  };
  return (
    <SelectFieldAutoComplete
      disabled={disabled}
      data-testid={selectFieldTestId}
      open={openPopper}
      onOpen={handleOpenPopper}
      onClose={handleClosePopper}
      multiple={false}
      size="medium"
      options={options}
      value={
        field.value
          ? options.find((opt) => opt?.member_group_id === field.value)
          : null
      }
      freeSolo
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={(_, newValue) =>
        field.onChange(
          newValue?.member_group_id ? newValue.member_group_id : null
        )
      }
      getOptionLabel={(option) => option?.member_group}
      getOptionSelected={(option, value) =>
        option.member_group_id === value.member_group_id
      }
      getOptionDisabled={(opt) =>
        selectedLabels.has(opt?.member_group_id) &&
        opt?.member_group_id !== field.value?.member_group_id
      }
      PopperComponent={(props) => (
        <SelectFieldDropdown
          {...props}
          style={{
            ...props.style,
            width: "100%",
          }}
        >
          {getDropDownContent(props.children)}
        </SelectFieldDropdown>
      )}
      renderOption={(option) => {
        const isInArray = inputValue && options.includes(inputValue);
        return (
          <div
            key={option?.member_group_id}
            data-disabled={isInArray}
            className="option-item"
          >
            {option?.member_group}
          </div>
        );
      }}
      renderInput={(params) => (
        <>
          <SelectLabel data-disabled={disabled}>{label}</SelectLabel>
          <SelectTextField
            {...params}
            data-disabled={disabled}
            data-testid={testId.integrations.selectField(formField)}
            fullWidth={false}
            placeholder={selectPlaceholder}
            autoFocus={false}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <EndAdornment>
                  {field.value && (
                    <CloseIcon
                      data-diabled={disabled}
                      className="clear-icon"
                      onClick={() => {
                        if (!disabled) {
                          const fields = ["member_group", "member_group_id"];
                          fields.forEach((field) => {
                            form.setValue(`${formField}.${field}`, null, {
                              shouldDirty: true,
                            });
                          });
                        }
                      }}
                    />
                  )}

                  <PopperIcon>
                    {!openPopper ? (
                      <ChevronDown
                        className="chevron-down"
                        data-diabled={disabled}
                        onClick={handleOpenPopper}
                        data-testid={testId.integrations.optionAccountTypeToggle(
                          formField
                        )}
                      />
                    ) : (
                      <ChevronUp
                        onClick={handleClosePopper}
                        data-testid={testId.integrations.optionAccountTypeToggle(
                          formField
                        )}
                      />
                    )}
                  </PopperIcon>
                </EndAdornment>
              ),
            }}
          />
        </>
      )}
      {...rest}
    />
  );
}
