import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Button } from "components/Button/Button";
import styled, { css } from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const AccountTypeFormWrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const AccountTypeFormButton = styled(Button)`
  align-self: flex-end;
  justify-self: flex-end;
  padding: 12px 32px;
  border-radius: 12px;
  font-size: 1rem;
  width: 100px;
  height: 48px;

  :disabled {
    background-color: ${COLORS.neutral.n3} !important;
  }

  @media screen and (max-width: 480px) {
    padding: 10px 24px;
    width: 84px;
    height: 44px;
  }
`;

export const SelectFieldAutoComplete = styled(Autocomplete)``;

export const PopperIcon = styled.div`
  display: flex;
  justify-self: flex-end;
  cursor: pointer;
  .chevron-down {
    color: ${COLORS.neutral.n6};
  }
  [data-disabled="true"] & {
    .chevron-down {
      color: ${COLORS.neutral.n400};
    }
  }
`;

export const SelectWithDropdown = styled.div`
  :not(:last-child) {
    margin-bottom: 20px;

    @media screen and (max-width: 480px) {
      margin-bottom: 16px;
    }
  }
`;

export const SelectLabel = styled.label`
  color: ${COLORS.neutral.n8};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;

  @media screen and (max-width: 480px) {
    font-size: 0.875rem;
    margin-bottom: 4.5px;
  }

  &[data-disabled="true"] {
    color: ${COLORS.neutral.n5};
  }
`;

export const SelectTextField = styled(TextField)`
  height: 46px;
  max-height: 56px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid ${COLORS.neutral.n6};
  display: flex;
  justify-content: center;
  margin-top: 8px;
  transition: background-color 250ms ease-out;

  @media screen and (max-width: 480px) {
    padding: 12px;
  }
  :hover {
    background-color: ${COLORS.neutral.n200};
  }

  .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    min-height: unset;
    border-radius: unset;
    border: unset;
    background: unset;
    padding: unset;
  }

  .MuiAutocomplete-inputRoot[class*="MuiInput-root"],
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    padding: unset;
    font-size: 1rem;
    @media screen and (max-width: 480px) {
      font-size: 0.875rem;
    }
    color: ${COLORS.neutral.n8};
    ::placeholder {
      color: ${COLORS.neutral.n6};
    }
  }

  &[data-disabled="true"] {
    border: 1px solid ${COLORS.neutral.n400};

    :hover {
      background-color: unset;
    }

    .MuiAutocomplete-inputRoot[class*="MuiInput-root"],
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
      .MuiAutocomplete-input:first-child {
      color: ${COLORS.neutral.n5};
      ::placeholder {
        color: ${COLORS.neutral.n400};
      }
    }
  }
`;

const dropdownScroll = css`
  scrollbar-width: thin;
  scrollbar-color: ${COLORS.neutral.n3} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLORS.neutral.n3};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.neutral.n3};
    border-radius: 10px;
    border: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: none;
  }
`;

export const DropdownBox = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid ${COLORS.neutral.n6};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04),
    0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  padding: 8px;
  margin-top: 6px;

  @media screen and (max-width: 480px) {
    margin-top: 4px;
  }

  & .MuiPaper-root {
    background-color: unset;
    color: ${COLORS.neutral.n8};
  }
  & .MuiAutocomplete-paper {
    margin: 0;
  }
  & .MuiPaper-elevation1 {
    box-shadow: unset;
  }
  & .MuiAutocomplete-option {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
    transition: background-color 250ms ease-out;
    min-height: 32px;
    font-size: 1rem;

    @media screen and (max-width: 480px) {
      font-size: 0.875rem;
      min-height: 29px;
    }
  }

  & .MuiAutocomplete-option[data-focus="true"] {
    background-color: unset;
  }
  & .MuiAutocomplete-option:hover {
    background-color: ${COLORS.neutral.n200};
  }
  & .MuiAutocomplete-option[aria-selected="true"] {
    background-color: unset;
    color: ${COLORS.neutral.n5};
    pointer-events: none;
  }
  & .MuiAutocomplete-listbox {
    padding: unset;
    max-height: 240px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: ${COLORS.neutral.n3} transparent;

    ${dropdownScroll}
  }

  & .MuiMenuItem-root {
    font-size: 1rem;

    @media screen and (max-width: 480px) {
      font-size: 0.875rem;
    }
    background-color: unset;

    :hover {
      background-color: unset;
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 24px;
    font-size: 1rem;
    color: ${COLORS.neutral.n6};

    @media screen and (max-width: 480px) {
      font-size: 0.875rem;
      padding: 20px 8px;
    }
  }
`;

export const EndAdornment = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 480px) {
    gap: 8px;
  }
  svg {
    cursor: pointer;
  }
  .clear-icon {
    color: ${COLORS.red.rd3};
  }
  [data-disabled="true"] & {
    .clear-icon {
      color: ${COLORS.red.rd2};
    }
  }
`;
