import { api } from "api/api";

export async function patchEnableFields({ neighborhood_id, gosprout_enabled }) {
  const { data } = await api.patch(
    `neighborhoods/${neighborhood_id}/settings`,
    {
      gosprout_enabled,
    }
  );
  return data;
}
