import React, { useEffect, lazy } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "./errorBoundary";
import SnackbarProvider from "../contextStore/SnackbarContext";
import data from "../propsData/index";
import sessionStatus from "../utilities/session/sessionStatus";
import ScrollToTop from "../ScrollToTop.jsx";
import MainApp from "./mainApp";
import CribLayout from "./cribLayout";
import CribNavigationLayout from "./cribNavigationLayout";
import NeighborhoodLayout from "./neighborhoodLayout";
import CustomRouteLayout from "./CustomRouteLayout";
import { cribNavigationRoutes, cribRoutes, mainAppRoutes } from "../routes";
import {
  baseRoutes,
  cribCustomRoutes,
  cribNavigationCustomRoutes,
} from "routes/customRoutes";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { EditNeighborhood } from "pages/Neighborhoods/EditNeighborhood/EditNeighborhood";
import BulletinPost_Provider from "../state/NeighborhooBulletin/BulletinPost/BulletinPost_Provider";
import SignIn from "../pages/Signin.js";
import CreateAccount from "../pages/CreateAccount.js";
import ConfirmSignup from "../pages/ConfirmSignup.js";
import SignupSuccess from "../pages/CreateAccountSuccess.js";
import VerifyAccount from "../pages/VerifyAccount.js";
import OnboardingNeighborhoodprofileStep1 from "../pages/OnboardingNeighborhoodprofileStep1";
import OnboardingNeighborhoodprofileStep2 from "../pages/OnboardingNeighborhoodprofileStep2";
import OnboardingNeighborhoodprofileStep3 from "../pages/OnboardingNeighborhoodprofileStep3";
import OnboardingNeighborhoodprofileStep4 from "../pages/OnboardingNeighborhoodprofileStep4";
import SetupCribStep1 from "../pages/SetupCribStep1";
import SetupCribStep2 from "../pages/SetupCribStep2";
import SetupCribStep3 from "../pages/SetupCribStep3";
import SetupCribStep4 from "../pages/SetupCribStep4";
import SetupCribStep5 from "../pages/SetupCribStep5";
import ForgotPassword from "../pages/ForgotPassword.js";
import ForgotPasswordConfirmation from "../pages/ForgotPasswordConfirmation.js";
import ResetPassword from "../pages/ResetPassword.js";
import PasswordResetSuccess from "../pages/PasswordResetSuccess.js";
import ExpiredSession from "../components/ExpiredSession/ExpiredSession";
import NotFoundPage from "../components/NotFound/NotFoundPage";
import NeighborhoodInviteLayout from "./NeighborhoodInviteLayout.jsx";
import CreateAccountFromInvite from "../pages/CreateAccountFromInvite/index.jsx";
import "../App.css";
import sessionInfo from "utilities/session/sessionInfo";

const NeighborhoodProfile = lazy(() => import("../pages/NeighborhoodProfile"));
const NeighborhoodOfficehours = lazy(() =>
  import("../pages/NeighborhoodOfficehours/neighborhood-officehours.jsx")
);
const NeighborhoodRegisterOfficeHour = lazy(() =>
  import("../pages/NeighborhoodRegisterOfficeHour/neighborhood-register.jsx")
);
// const NeighborhoodCoaches = lazy(() =>
//   import(
//     "../pages/NeighborhoodOfficehours/NeighborhoodCoaches/NeighborhoodCoaches"
//   )
// );
const NeighborhoodOfficeHoursCongrats = lazy(() =>
  import(
    "../pages/NeighborhoodOfficeHoursCongrats/neighborhood-officehours-congrats.jsx"
  )
);
const SuggestedNeighborhood = lazy(() =>
  import("../pages/SuggestedNeighborhood")
);
const ViewNeighborhhoodBulletinPost = lazy(() =>
  import("../pages/ViewNeighborhhoodBulletinPost.js")
);
const BulletinBoardPost = lazy(() => import("../pages/BulletinBoardPost.js"));
const Forum = lazy(() => import("../pages/Forum"));
const ForumComment = lazy(() => import("../pages/ForumComment"));
const NeighborhoodResources = lazy(() =>
  import("../pages/NeighborhoodResources.js")
);
const UploadResource = lazy(() => import("../pages/UploadResource.js"));
const FinalizeYourResource = lazy(() =>
  import("../pages/FinalizeYourResource.js")
);
const ResourceUploads = lazy(() => import("../pages/ResourceUploads.js"));
const ResourceUploaded = lazy(() => import("../pages/ResourceUploaded.js"));
const EditResource = lazy(() => import("../pages/EditAResource.js"));
const NeighborhoodMembers = lazy(() =>
  import("../pages/NeighborhoodMembers/NeighborhoodMembers")
);
const RedirectExistingUser = lazy(() =>
  import("../pages/RedirectExistingUser.js")
);
const TakeATour = lazy(() => import("../pages/TakeATour"));

const Container = () => {
  const queryClient = new QueryClient();
  const { token } = sessionInfo();
  const navigate = useNavigate();

  const model = {
    Forms: data.forms,
    Components: data.components,
  };

  const path = window.location.pathname;
  const noReroutePath =
    path !== "/" &&
    !path.includes("/sign-in") &&
    !path.includes("/sign-up") &&
    !path.includes("/signup") &&
    !path.includes("/forgot-password") &&
    !path.includes("/reset-password") &&
    !path.includes("/setup") &&
    path !== "/reset-password-success" &&
    path !== "/session-expired" &&
    path !== "/whyRialto" &&
    path !== "/features" &&
    path !== "/pricing" &&
    path !== "/privacy" &&
    path !== "/terms" &&
    path !== "/linkedin" &&
    path !== "/product" &&
    path !== "/faq";

  useEffect(() => {
    if (noReroutePath) {
      if (!token) {
        navigate("sign-in");
      } else {
        if (sessionStatus() < 3)
          window.location.href = `${window.location.origin}/session-expired?ref=${window.location.href}`;
      }
    }
  }, [token]);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <ScrollToTop />
          <Routes>
            {/* Landing page routes */}
            <Route path="/" element={<MainApp />}>
              {mainAppRoutes.map(({ path, Component }, index) => (
                <Route
                  key={`r-app-${index}`}
                  path={path}
                  element={<Component />}
                />
              ))}
            </Route>

            {/* Custom Routes */}
            <Route element={<CustomRouteLayout />}>
              {/* Custom Base Routes */}
              {baseRoutes.map(({ path, Component }, index) => (
                <Route
                  key={`r-custom-base-${index}`}
                  path={path}
                  element={<Component />}
                />
              ))}

              {/* Custom Crib Routes */}
              <Route path="/" element={<CribLayout />}>
                {cribCustomRoutes.map(({ path, Component }, index) => (
                  <Route
                    key={`r-custom-crib-${index}`}
                    path={path}
                    element={<Component />}
                  />
                ))}
              </Route>
              <Route path="/" element={<CribNavigationLayout />}>
                {cribNavigationCustomRoutes.map(
                  ({ path, Component }, index) => (
                    <Route
                      key={`r-custom-app-crib-${index}`}
                      path={path}
                      element={<Component />}
                    />
                  )
                )}
              </Route>
            </Route>

            {/* Crib Routes */}
            <Route path="/" element={<CribLayout />}>
              {cribRoutes.map(({ path, Component }, index) => (
                <Route
                  key={`r-app-crib-${index}`}
                  path={path}
                  element={<Component />}
                />
              ))}
            </Route>
            <Route path="/" element={<CribNavigationLayout />}>
              {cribNavigationRoutes.map(({ path, Component }, index) => (
                <Route
                  key={`r-crib-${index}`}
                  path={path}
                  element={<Component />}
                />
              ))}
            </Route>

            {/*  Neighborhood Routes */}
            <Route path="/" element={<NeighborhoodLayout />}>
              <Route
                path="/neighborhood/:neighborhood_id"
                element={<NeighborhoodProfile />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/edit"
                element={<EditNeighborhood />}
              />
              <Route
                path="/SuggestedNeighborhood"
                element={<SuggestedNeighborhood />}
              />
              <Route path="/tour" element={<TakeATour />} />
              <Route
                path="/neighborhood/:neighborhood_id/bulletin/:bulletin_id"
                element={<ViewNeighborhhoodBulletinPost />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/bulletin"
                element={
                  <BulletinPost_Provider>
                    <BulletinBoardPost />
                  </BulletinPost_Provider>
                }
              />
              <Route
                path="/neighborhood/:neighborhood_id/forum"
                element={<Forum />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/forumcomment/:forum_post_id"
                element={<ForumComment />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/library/resources"
                element={<NeighborhoodResources />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/library/resources/upload"
                element={<UploadResource />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/library/resources/upload/finalize"
                element={<FinalizeYourResource />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/library/resources/:resource_id"
                element={<ResourceUploads />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/existing_member/:token"
                element={<RedirectExistingUser />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/library/resources/:resource_id/upload/success"
                element={<ResourceUploaded />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/library/resources/:resource_id/edit"
                element={<EditResource />}
              />
              {/* <Route
            path="/neighborhood/:neighborhood_id/library/coaches/:coach_id"
            element={<NeighborhoodRegisterOfficeHour />}
          /> */}
              <Route
                path="/neighborhood/:neighborhood_id/library/coaches/:officehour_id"
                element={<NeighborhoodRegisterOfficeHour />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/library/office-hours"
                element={
                  <NeighborhoodOfficehours
                    bannerModel={model.Components.CribsProfileBanner}
                  />
                }
              />
              {/* <Route
                path="/neighborhood/:neighborhood_id/library/coaches"
                element={
                  <NeighborhoodCoaches
                    bannerModel={model.Components.CribsProfileBanner}
                  />
                }
              /> */}
              <Route
                path="/neighborhood/:neighborhood_id/library/coaches/:officehour_id/officehour_congrats"
                element={<NeighborhoodOfficeHoursCongrats />}
              />
              <Route
                path="/neighborhood/:neighborhood_id/members"
                element={<NeighborhoodMembers />}
              />
            </Route>

            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<CreateAccount />} />
            <Route path="/linkedin" element={<LinkedInPopUp />} />
            <Route path="/sign-up/confirm" element={<ConfirmSignup />} />
            <Route path="/sign-up/success" element={<SignupSuccess />} />
            <Route
              path="/account/:account_id/verify/:token"
              element={<VerifyAccount />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password-confirmation"
              element={<ForgotPasswordConfirmation />}
            />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/reset-password-success"
              element={<PasswordResetSuccess />}
            />
            <Route
              path="/setup/neighborhoodprofile/step1"
              element={
                <OnboardingNeighborhoodprofileStep1
                  form={model.Forms.OnboardingNeighborhoodprofileFormFields}
                  step={"step1"}
                />
              }
            />
            <Route
              path="/setup/neighborhoodprofile/step2"
              element={
                <OnboardingNeighborhoodprofileStep2
                  form={model.Forms.OnboardingNeighborhoodprofileFormFields}
                  step={"step2"}
                />
              }
            />
            <Route
              path="/setup/neighborhoodprofile/step3"
              element={<OnboardingNeighborhoodprofileStep3 />}
            />
            <Route
              path="/setup/neighborhoodprofile/step4"
              element={
                <OnboardingNeighborhoodprofileStep4
                  form={model.Forms.OnboardingNeighborhoodprofileFormFields}
                  step={"step4"}
                />
              }
            />
            <Route
              path="/setup/crib/step1"
              element={
                <SetupCribStep1 form={model.Forms.SetupCrib} step={"step1"} />
              }
            />
            <Route
              path="/setup/crib/step2"
              element={
                <SetupCribStep2 form={model.Forms.SetupCrib} step={"step2"} />
              }
            />
            <Route
              path="/setup/crib/step3"
              element={
                <SetupCribStep3 form={model.Forms.SetupCrib} step={"step3"} />
              }
            />
            <Route
              path="/setup/crib/step4"
              element={
                <SetupCribStep4 form={model.Forms.SetupCrib} step={"step4"} />
              }
            />
            <Route
              path="/setup/crib/step5"
              element={
                <SetupCribStep5 form={model.Forms.SetupCrib} step={"step5"} />
              }
            />
            <Route path="/" element={<NeighborhoodInviteLayout />}>
              <Route
                path="/neighborhood/:neighborhood_id/invite/:invite_id/signup"
                element={<CreateAccountFromInvite />}
              />
            </Route>
            <Route path="/session-expired" element={<ExpiredSession />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </SnackbarProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default Container;
