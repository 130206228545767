export const COLORS = {
  neutral: {
    n0: "#FFFFFF",
    n1: "#FDFDFD",
    n2: "F5F5F5",
    n3: "#c5cdd8",
    n5: "#9DACBE",
    n6: "#8a9bb1",
    n7: "#738194",
    n8: "#5C6776",
    n9: "#454E59",
    n200: "#d8dee5",
    n400: "#B1BCCB",
  },
  purple: {
    p1: "#C5A3C9",
    p300: "#784870",
    p400: "#5E2F56",
    p500: "#461C3F",
    p600: "#644167",
  },
  gray: {
    gray02: "#4F4F4F",
    gr1: "#333333",
    gr2: "#f5f5f5",
    gr3: "#828282",
    gr6: "#f2f2f2",
  },
  green: {
    grn1: "#3b7a71",
  },
  gold: {
    go400: "#F7C461",
    go500: "#F3A712",
    go600: "#F9D388",
    go700: "#CA8B0F",
  },
  blue: {
    b1: "#1F49DF",
  },
  red: {
    rd1: "#EB5757",
    rd2: "#f2b9b5",
    rd3: "#e6736a",
  },
  yellow: {
    y1: "#fdedd0",
    y2: "#FBE2B0",
  },
};
