import { api } from "api/api";

export async function getConnectedAccounts(params) {
  const { neighborhood_id } = params;
  const { data } = await api.get(
    `neighborhoods/${neighborhood_id}/member_groups/connected-accounts/goSprout/stats`
  );

  return data;
}
