import React from "react";

import { useQuery } from "react-query";
import { IntegrationsAccountTypesDialogRaw } from "./IntegrationsAccountTypesDialog.raw";
import store from "utilities/storage";
import { useParams } from "react-router-dom";
import { IntegrationsAccountTypesDialogLoading } from "./IntegrationsAccountTypesDialog.loading";
import { getGoSproutSettings } from "api/integrations/getGoSproutSettings";
import { getConnectedAccounts } from "api/integrations/getConnectedAccounts";

export function IntegrationsAccountTypesDialogQuery({ onClose }) {
  const { neighborhood_id } = useParams();

  const _neighborhood_id = store.retrieve("neighborhood_id");
  const possibleNeighbohoodId = neighborhood_id ?? _neighborhood_id;

  const goSproutSettingQuery = useQuery({
    queryKey: ["gosprout-settings", possibleNeighbohoodId],
    queryFn: () =>
      getGoSproutSettings({ neighborhood_id: possibleNeighbohoodId }),
  });
  const goSproutConnectedAccountsQuery = useQuery({
    queryKey: ["gosprout-connect-account", possibleNeighbohoodId],
    queryFn: () =>
      getConnectedAccounts({ neighborhood_id: possibleNeighbohoodId }),
  });
  const isLoading =
    goSproutConnectedAccountsQuery.isLoading ||
    goSproutConnectedAccountsQuery.isIdle ||
    goSproutSettingQuery.isIdle ||
    goSproutSettingQuery.isLoading;

  if (isLoading) {
    return <IntegrationsAccountTypesDialogLoading />;
  }

  if (
    !goSproutSettingQuery.data.data ||
    !goSproutConnectedAccountsQuery.data.data
  ) {
    return <IntegrationsAccountTypesDialogLoading />;
  }

  return (
    <IntegrationsAccountTypesDialogRaw
      gosprout_settings={goSproutSettingQuery.data.data}
      onClose={onClose}
      connectedAccounts={goSproutConnectedAccountsQuery.data.data}
    />
  );
}
