import { Tooltip, withStyles } from "@material-ui/core";
import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const RightContentDiv = styled.div`
  > :not(:last-child) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    > :not(:last-child) {
      margin-bottom: unset;
    }
  }
`;

export const RightSubContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .titleNode {
    display: flex;
    align-items: center;
    gap: 4px;

    .title {
      &[data-disabled="true"] {
        color: ${COLORS.neutral.n5};
      }
      color: ${COLORS.neutral.n8};
      font-weight: 600;
      font-size: 0.875rem;
    }
  }
  .list {
    li {
      :not(:last-child) {
        margin-bottom: 8px;
      }
      a {
        font-size: 0.875rem;
        color: ${COLORS.gold.go700};

        &[data-disabled="true"] {
          color: ${COLORS.gold.go400};
          pointer-events: none;
        }
      }
    }
  }
`;

export const ConnectedAccountItemText = styled.p`
  font-size: 0.875rem;
  color: ${COLORS.neutral.n8};

  &[data-disabled="true"] {
    color: ${COLORS.neutral.n5};
  }
`;

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: COLORS.yellow.y1,
    color: COLORS.neutral.n8,
    fontSize: "0.875rem",
    borderRadius: "12px",
    padding: 12,
    zIndex: 9994,
    textAlign: "center",
    width: "293px",
    maxWidth: "293px",
  },
  popper: {
    zIndex: 9994,
    maxWidth: "293px",
  },
  arrow: {
    color: COLORS.yellow.y1,
    fontSize: "1.5rem",
  },
  tooltipArrow: {
    "&::before": {
      borderWidth: "2px",
    },
  },
  [theme.breakpoints.down("xs")]: {
    tooltip: {
      width: "218px",
      maxWidth: "218px",
      backgroundColor: COLORS.yellow.y2,
      padding: 8,
    },
    popper: {
      maxWidth: "218px",
    },
    arrow: {
      color: COLORS.yellow.y2,
    },
  },
}))(Tooltip);
