import React, { forwardRef } from "react";
import { Menu, useMenuState } from "@szhsin/react-menu";
import { DropdownItem } from "./DropdownPopover.styles";
import classes from "./DropdownPopover.module.css";
import { ReactComponent as VericalMore } from "assets/svgs/vertical-more.svg";

export const DropdownPopover = forwardRef((props, ref) => {
  const { items, onClose, onOpen, align, target } = props;

  const [menuProps, toggleMenu] = useMenuState({
    transition: false,
    unmountOnClose: true,
    initialMounted: false,
  });

  const isOpen = menuProps.state === "open";

  const closeMenu = () => {
    toggleMenu(false);
    onClose && onClose();
  };

  const openMenu = () => {
    toggleMenu(true);
    onOpen && onOpen();
  };

  const targetProps = {
    btnProps: {
      onClick: openMenu,
    },
    ref,
    isOpen,
  };

  function getTarget() {
    if (target) {
      return target(targetProps);
    }
    if (isOpen) {
      return (
        <div
          className={classes["target-interactive"]}
          {...targetProps.btnProps}
        >
          <VericalMore className={classes["interactive-icon"]} />
        </div>
      );
    }
    return (
      <div className={classes.target} {...targetProps.btnProps}>
        <VericalMore className={classes["uninteractive-icon"]} />
      </div>
    );
  }
  return (
    <Menu
      {...menuProps}
      menuButton={<div ref={ref}>{getTarget()}</div>}
      align={align || "end"}
      anchorRef={ref}
      menuClassName={classes["menu-list"]}
      portal
      position="anchor"
      className="new-icon"
      onItemClick={closeMenu}
      onMenuChange={(e) => toggleMenu(e.open)}
      gap={4}
    >
      {items.map((item) => (
        <DropdownItem
          key={item.label}
          onClick={() => {
            item.onClick();
          }}
        >
          {item.label}
        </DropdownItem>
      ))}
    </Menu>
  );
});
