import React, { useState } from "react";

import { IntegrationsCard } from "components/IntegrationsCard/IntegrationsCard";
import { ReactComponent as GoSproutLogo } from "assets/svgs/gosprout-img.svg";
import { LearnMore, StyledModal } from "./IntegrationsDialog.styles";
import { Link } from "react-router-dom";
import { ReactComponent as HelpIcon } from "assets/svgs/help-icon.svg";
import { IntegrationsAccountTypesDialog } from "components/IntegrationsAccountTypesDialog/IntegrationsAccountTypesDialog";
import { useOutsideClick } from "hooks";

export function IntegrationsDialog({ open, onClose }) {
  const [openAccountTypes, setOpenAccountTypes] = useState(false);

  const closeAllModals = () => {
    setOpenAccountTypes(false);
    onClose();
    document.body.style.overflowY = "auto";
  };

  const integrationDialogRef = useOutsideClick(closeAllModals);
  if (!open) return null;
  return (
    <>
      <StyledModal
        title="All apps"
        isOpened={open}
        onClose={onClose}
        overlayProps={{
          ref: integrationDialogRef,
        }}
      >
        <IntegrationsCard
          logo={<GoSproutLogo className="logo" />}
          title="GoSprout"
          description="Connect your neighborhood members to your apprenticeships in GoSprout."
          onClick={() => setOpenAccountTypes(true)}
        />
        <LearnMore className="new-icon">
          <HelpIcon />
          <Link
            rel="noreferrer"
            target="_blank"
            to="https://help.rialtoapp.com/en/articles/10028074-integrations-on-rialto"
          >
            Learn more about integrations
          </Link>
        </LearnMore>
      </StyledModal>
      <IntegrationsAccountTypesDialog
        open={openAccountTypes}
        onClose={() => setOpenAccountTypes(false)}
        closeAllModals={closeAllModals}
      />
    </>
  );
}
