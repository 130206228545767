/**
 * @description Define Elements that'll always be visible throughout the app's lifecycle here.
 *
 * See https://stackoverflow.com/a/70798950 for more info on potential memory leaks from this.
 */
class StaticElements {
  portal;

  constructor() {
    const dom = typeof document === "undefined" ? null : document;
    this.portal = dom?.getElementById("overlays") || null;
  }
}

export default new StaticElements();
