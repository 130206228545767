import { MenuItem } from "@szhsin/react-menu";
import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const DropdownItem = styled(MenuItem)`
  color: ${COLORS.neutral.n8};
  font-size: 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  list-style: none;
  padding: 4px 16px;
  transition: background-color 250ms ease-out;

  @media screen and (max-width: 480px) {
    font-size: 0.875rem;
    padding: 6px 16px;
  }

  :last-child {
    border-radius: 0px 0px 12px 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 12px;
  }
  :nth-child(2) {
    border-radius: 12px 12px 0px 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 4px;
  }

  :not(:last-child) {
    margin-bottom: 4px;
  }
  :hover {
    background-color: ${COLORS.neutral.n3};
  }
`;
