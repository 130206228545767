import React from "react";
import ReactDom from "react-dom";
import { Body, Overlay } from "./GlobalModal.styles";
import StaticElements from "utilities/StaticElements";
import clsx from "clsx";
import { GlobalModalHeader } from "./GlobalModalHeader";

export function GlobalModal({
  children,
  isOpened,
  classNames,
  fullscreen,
  overlayProps,
  headerNode,
  title,
  titleNode,
  onClose,
  ...rest
}) {
  const {
    onClick,
    ref: overlayRef,
    ...remainingOverlayProps
  } = overlayProps || {};
  return isOpened && StaticElements.portal
    ? ReactDom.createPortal(
        <Overlay
          className={clsx(classNames?.overlay, remainingOverlayProps.className)}
          ref={overlayRef}
          onClick={(e) => e.stopPropagation()}
        >
          <Body
            {...rest}
            className={clsx(classNames?.body, rest.className, "new-icon")}
            data-fullscreen={fullscreen}
          >
            {headerNode || (
              <GlobalModalHeader
                title={title}
                titleNode={titleNode}
                onClose={onClose}
              />
            )}
            {children}
          </Body>
        </Overlay>,
        StaticElements.portal
      )
    : null;
}
