import { api } from "api/api";

export async function getGoSproutSettings(params) {
  const { neighborhood_id } = params;
  const { data } = await api.get(
    `neighborhoods/${neighborhood_id}/gosprout-settings`
  );

  return data;
}
