import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import api from "../../api";
import isEmpty from "../../utilities/isEmpty";
import sessionInfo from "../../utilities/session/sessionInfo";
import store from "../../utilities/storage";
import { NeighborhoodsQuery } from "./Neighborhoods.query";

function Neighborhoods() {
  const [pictureUrl, setPictureUrl] = useState("");
  const { crib_id } = useParams();
  const user_id = !isEmpty(crib_id) ? crib_id : sessionInfo.account_id;

  const neighborhoodsQuery = useQuery({
    queryKey: ["neighborhoods", user_id],
    queryFn: () => api.Crib.getCribProfile(user_id),
    onSuccess: async ({ data }) => {
      const { crib } = data;
      const { job_title, workplace, profile_picture, neighborhood_id } = crib;

      const cribPicture = await api.Crib.getCribPicture(neighborhood_id);
      setPictureUrl(cribPicture.data);
      store.write("profile_picture", profile_picture);
      store.write("JobTitle", job_title);
      store.write("workplace", workplace);
    },
  });

  return (
    <NeighborhoodsQuery
      neighborhoodsQuery={neighborhoodsQuery}
      pictureUrl={pictureUrl}
    />
  );
}

export default Neighborhoods;
