import styled from "styled-components";
import { COLORS } from "styles/colors/minwo";

export const IntegrationsCardDiv = styled.div`
  padding: 16px 16px 20px 16px;
  width: 304px;
  height: 196px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid ${COLORS.neutral.n3};
  transition: background-color 250ms ease-out;

  @media screen and (max-width: 480px) {
    margin: auto;
    width: 100%;
    padding: 12px 12px 16px 12px;
    gap: 12px;
    height: 179px;
  }

  svg {
    width: 44px;
    height: 44px;

    @media screen and (max-width: 480px) {
      width: 32px;
      height: 32px;
    }
  }

  :hover {
    cursor: pointer;
    background-color: ${COLORS.neutral.n200};
  }
`;

export const IntegrationsCardBottomDiv = styled.div`
  h3 {
    color: ${COLORS.neutral.n9};
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 130%;

    @media screen and (max-width: 480px) {
      font-size: 1rem;
    }
  }
  p {
    color: ${COLORS.neutral.n8};
    font-size: 1rem;
    @media screen and (max-width: 480px) {
      font-size: 0.875rem;
    }
  }
`;
