import React from "react";
import ErrorPageIllustration from "assets/svgs/ErrorPageIllustration";
import "./ErrorPage.css";

const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="error-page__svg--container">
        <ErrorPageIllustration className="error-page__svg" />
      </div>
      <div className="error-page__text--container">
        <h2 className="error-page__header">Oops! Something's not right.</h2>
        <p className="error-page__text">
          We're experiencing technical difficulties on our end. It's not your
          fault, it's ours! Our team is already working hard to fix the issue.
          Sorry for any inconvenience. Reach out to rialto@minwo.co if you need
          help. Thanks for your patience.
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
