import { DropdownPopover } from "components/DropdownPopover/DropdownPopover";
import { IntegrationsDialog } from "components/IntegrationsDialog/IntegrationsDialog";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import getNeighborhoodAccessRight from "utilities/getNeighborhoodAccessRight";
import sessionInfo from "utilities/session/sessionInfo";

export function BannerDropdown({ neighborhood_id }) {
  const [openGoSproutInt, setOpenGoSproutInt] = useState(false);

  const dropdownPopoverRef = useRef();
  const { role } = sessionInfo();
  const isUserTypeBCO = role && role === "BCO";
  const isNeighborhoodOwner = getNeighborhoodAccessRight(neighborhood_id);
  const isRialtoAdmin = role && role === "RialtoAdmin";

  const navigate = useNavigate();
  if (!isRialtoAdmin && !isUserTypeBCO && isNeighborhoodOwner !== 3)
    return null;

  return (
    <div className="banner-dropdown" onClick={(e) => e.stopPropagation()}>
      <DropdownPopover
        ref={dropdownPopoverRef}
        items={[
          {
            label: "Edit Neighborhood profile",
            onClick: () => navigate(`neighborhood/${neighborhood_id}/edit`),
          },
          {
            label: "Contact Support",
            onClick: () => window.open("https://help.rialtoapp.com/en/"),
          },
          {
            label: "App Integrations",
            onClick: () => {
              setOpenGoSproutInt(true);
              document.body.style.overflowY = "hidden";
            },
          },
        ]}
      />

      <IntegrationsDialog
        open={openGoSproutInt}
        onClose={() => {
          setOpenGoSproutInt(false);
          document.body.style.overflowY = "auto";
        }}
      />
    </div>
  );
}
