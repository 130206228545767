import React from "react";
import { Icon } from "@blueprintjs/core";

import { Header, Title } from "./GlobalModal.styles";
import { COLORS } from "styles/colors/minwo";
import clsx from "clsx";

export function GlobalModalHeader(args) {
  const { title, onClose, titleNode, ...rest } = args;
  const getTitle = () => {
    if (titleNode) return titleNode;
    if (typeof title === "string") {
      return (
        <div className="left">
          <button className="back-button" onClick={onClose}>
            BACK
          </button>
          <Title>{title}</Title>
        </div>
      );
    }

    return title;
  };

  return (
    <Header {...rest} className={clsx(rest.className, "new-icon")}>
      {getTitle()}
      <Icon
        icon="cross"
        color={COLORS.neutral.n9}
        onClick={onClose}
        className="close-icon"
      />
    </Header>
  );
}
