import React from "react";
import {
  AccountTypeFormButton,
  AccountTypeFormWrapper,
  SelectWithDropdown,
} from "./IntegrationsAccountTypeForm.styles";
import { Controller, useForm } from "react-hook-form";
import {
  accountTypeFormSchema,
  createAccountTypeForm,
} from "../utils/AccountTypeForm.utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectField } from "./SelectField";
import * as testId from "utilities/testIds";
import { useMutation, useQueryClient } from "react-query";
import { postGoSproutIntegration } from "api/integrations/postGoSproutIntegration";
import store from "utilities/storage";
import { useParams } from "react-router-dom";

export function IntegrationsAccountTypeForm({ gosprout_settings, onClose }) {
  const { neighborhood_id } = useParams();
  const _neighborhood_id = store.retrieve("neighborhood_id");
  const possibleNeighbohoodId = neighborhood_id ?? _neighborhood_id;
  const form = useForm({
    defaultValues: createAccountTypeForm(gosprout_settings?.member_groups),
    resolver: yupResolver(accountTypeFormSchema),
    mode: "onChange",
  });
  const queryClient = useQueryClient();

  const {
    formState: { isDirty },
  } = form;

  const accountTypes = [
    {
      name: "sponsor",
      label: "Sponsors",
    },
    {
      name: "employer",
      label: "Employers",
    },
    {
      name: "journey_worker",
      label: "Journey Workers",
    },
    {
      name: "rti_provider",
      label: "RTI Providers",
    },
    {
      name: "apprentice",
      label: "Apprentices",
    },
  ];

  const postGoSproutInt = useMutation({
    mutationFn: postGoSproutIntegration,
    onSuccess: () => {
      queryClient.invalidateQueries([
        "gosprout-settings",
        possibleNeighbohoodId,
      ]);
      onClose();
    },
  });

  return (
    <>
      <AccountTypeFormWrapper>
        {accountTypes.map((each) => (
          <SelectWithDropdown key={each.name}>
            <Controller
              control={form.control}
              key={each.name}
              name={`${each.name}.member_group_id`}
              render={({ field }) => (
                <SelectField
                  key={each.name}
                  form={form}
                  formField={each.name}
                  selectPlaceholder="Select a Member Group"
                  label={each.label}
                  gosprout_settings={gosprout_settings}
                  selectFieldTestId={testId.integrations.selectFieldAutoComplete(
                    each.name
                  )}
                  field={field}
                />
              )}
            />
          </SelectWithDropdown>
        ))}
      </AccountTypeFormWrapper>
      <AccountTypeFormButton
        data-testid={testId.integrations.accountTypeConnectButton}
        size="small"
        kind="secondary"
        disabled={
          !isDirty ||
          !gosprout_settings?.gosprout_enabled ||
          postGoSproutInt.isLoading
        }
        onClick={() => {
          const body = accountTypes.map(({ label, name }) => [
            label,
            form.watch(`${name}.member_group_id`),
          ]);

          postGoSproutInt.mutate({
            neighborhood_id: possibleNeighbohoodId,
            body,
          });
        }}
      >
        Done
      </AccountTypeFormButton>
    </>
  );
}
