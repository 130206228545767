import React from "react";

import {
  StyledGlobalModalHeader,
  StyledModal,
} from "./IntegrationsAccountTypesDialog.styles";
import { Divider } from "@material-ui/core";
import { IntegrationsAccountTypesDialogQuery } from "./IntegrationsAccountTypesDialog.query";
import { useOutsideClick } from "hooks";

export function IntegrationsAccountTypesDialog({
  open,
  onClose,
  closeAllModals,
}) {
  const integrationDialogRef = useOutsideClick(closeAllModals);
  if (!open) return null;

  return (
    <StyledModal
      isOpened={open}
      onClose={onClose}
      overlayProps={{
        ref: integrationDialogRef,
      }}
      headerNode={
        <StyledGlobalModalHeader
          title={
            <button className="title" onClick={onClose}>
              BACK
            </button>
          }
          onClose={onClose}
          className="modalHeader"
        />
      }
    >
      <Divider />
      <IntegrationsAccountTypesDialogQuery onClose={onClose} />
    </StyledModal>
  );
}
