import React from "react";
import crib from "api/crib";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import sessionInfo from "utilities/session/sessionInfo";
import classes from "./NeighborhoodsDropdown.module.css";
import { COLORS } from "styles/colors/minwo";
import { Skeleton } from "@material-ui/lab";

export function NeighborhoodsDropdown(props) {
  const { closeMenu } = props;
  const { crib_id } = useParams();
  const { account_id } = sessionInfo();
  const possibleCribId = crib_id || account_id;

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["crib", "neighborhoods", possibleCribId],
    queryFn: () => crib.getCribProfile(possibleCribId, true),
    enabled: !!possibleCribId,
  });

  const handleClickListItem = () => {
    setAnchorEl(!anchorEl);
  };

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setAnchorEl(false);
  };

  const renderDropdownMenu = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton
            variant="rect"
            animation="pulse"
            style={{ width: "100%", height: 8, borderRadius: "8px" }}
          />
          <Skeleton
            variant="rect"
            animation="pulse"
            style={{
              width: "100%",
              height: 8,
              borderRadius: "8px",
              marginTop: 8,
            }}
          />
        </>
      );
    }

    return (
      <div className={classes["menu-list"]}>
        {data?.data?.neighborhoods?.map((option, index) => (
          <div
            key={option?.neighborhood_id}
            selected={index === selectedIndex}
            onClick={(event) => {
              handleMenuItemClick(event, index);
              navigate(`neighborhood/${option?.neighborhood_id}/edit`);
              closeMenu();
            }}
            className={classes.menu}
          >
            {option?.neighborhood_name}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div
        className={`${classes.target} new-icon`}
        onClick={handleClickListItem}
      >
        <p className={classes.target_title}>Edit Neighborhood Profile </p>
        <Icon
          icon={anchorEl ? "chevron-up" : "chevron-down"}
          color={COLORS.purple.p500}
          size={8}
        />
      </div>

      {anchorEl && (
        <div className={classes["dropdown-menu"]}>{renderDropdownMenu()}</div>
      )}
    </div>
  );
}
