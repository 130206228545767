export const sandiChatbot = {
  headerTitle: "sandi-title",
  toggleButton: "toggle-button",
  botDisplay: "bot-display",
  freeTextInput: "free-text-input",
  freeTextButton: "free-text-button",
  messageChildBox: "message-child-box",
  messageOptionsBox: "message-options-box",
  conversationList: "conversation-list",
  chatHistory: "chats",
  botContainer: "bot-container",
  typingIndicator: "typing-indicator",
  messageBox: "message-box",
  optionSelectionToggle: "option-selection-toggle",
  submitLocationButton: "submit-location-button",
  selectionChips: "selection-chips",
  selectedChips: (index) => `selected-chips-${index}`,
  singleCheckbox: "single-checkbox",
  submitAnswersButton: "submit-answers-button",
  checkedValues: (index) => `checked-values-${index}`,
};

export const neighborhoods = {
  inputField: (name) => `input-field-${name}`,
  editNeighborhoodSaveButton: "edit-neighborhood-save-button",
};

export const integrations = {
  selectField: (name) => `select-field-${name}`,
  optionAccountTypeToggle: (name) => `option-account-type-toggle-${name}`,
  accountTypeConnectButton: "account-type-connect-button",
  connectToggle: "connect-toggle",
  selectFieldAutoComplete: (name) => `select-field-auto-complete-${name}`,
  selectOption: "select-option",
};
