import React from "react";
import * as testId from "utilities/testIds";
import { CustomSwitch } from "./LeftContent.styles";

export function ToggleAccountSwitch({ gosprout_enabled, ...rest }) {
  return (
    <div
      className="switch-section"
      data-testid={testId.integrations.connectToggle}
    >
      <p>Enable</p>
      <CustomSwitch checked={gosprout_enabled} {...rest} />
    </div>
  );
}
