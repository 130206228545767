import axios from "axios";
import urls from "./baseUrl.js";
import sessionInfo from "utilities/session/sessionInfo.js";

const { token } = sessionInfo();

const crib = {
  async setupCrib(formData) {
    const url = `${urls.base_url}crib/setup`;
    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async setupUserCrib(formData) {
    const url = `${urls.base_url}crib`;
    try {
      const response = await axios.put(url, formData, {
        headers: { Authorization: `Bearer: ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  editCribProfile: async (crib_id, form) => {
    try {
      const formData = new FormData();
      Object.keys(form).forEach((key) => {
        formData.append(key, form[key]);
      });

      const resp = await axios.patch(
        `${urls.base_url}crib/${crib_id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer: ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return resp;
    } catch (error) {
      return error;
    }
  },
  getCribProfile: async (crib_id, strict = false) => {
    try {
      const resp = await axios.get(`${urls.base_url}crib/${crib_id}`, {
        params: {
          strict,
        },
        headers: { Authorization: `Bearer: ${token}` },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  getCribPicture: async (crib_id) => {
    try {
      const resp = await axios.get(
        `${urls.base_url}crib/profilepic/${crib_id}`,
        { headers: { Authorization: `Bearer: ${token}` } }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getCribNeighborhoodPictures: async (crib_id) => {
    try {
      const resp = await axios.get(
        `${urls.base_url}crib/neighborpic/${crib_id}`,
        { headers: { Authorization: `Bearer: ${token}` } }
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getNeighborhoodApps: async () => {
    try {
      const resp = await axios.get(`${urls.base_url}crib/neighborhood-apps`, {
        headers: { Authorization: `Bearer: ${token}` },
      });
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
  async grantAccess(payload) {
    const url = `${urls.base_url}crib/neighborhood-apps/access-control`;
    try {
      const response = await axios.patch(url, payload, {
        headers: { Authorization: `Bearer: ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default crib;
