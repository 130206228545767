import React from "react";
import { COLORS } from "styles/colors/minwo";
import { MenuBox } from "./CustomPopper.styles";

export const CustomPopper = ({ children, style, ...props }) => {
  const customStyles = {
    width: "80%",
    backgroundColor: COLORS.gray.gr2,
    ...style,
  };

  return (
    <MenuBox style={customStyles} {...props}>
      {children}
    </MenuBox>
  );
};
