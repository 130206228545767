import React from "react";
import { LeftContentDiv } from "./LeftContent.styles";
import { LeftContentHeader } from "./LeftContentHeader";
import { IntegrationsAccountTypeForm } from "../IntegrationsAccountTypeForm/IntegrationsAccountTypeForm";

export function LeftContent({
  logo,
  title,
  header_description,
  gosprout_settings,
  onClose,
}) {
  return (
    <LeftContentDiv>
      <LeftContentHeader
        logo={logo}
        title={title}
        description={header_description}
        gosprout_settings={gosprout_settings}
      />
      <div className="bottom">
        <div>
          <h3>{title} Account Types</h3>
          <p>
            Connect account types in {title} to the member groups you created in
            Rialto.
          </p>
        </div>

        <IntegrationsAccountTypeForm
          gosprout_settings={gosprout_settings}
          onClose={onClose}
        />
      </div>
    </LeftContentDiv>
  );
}
