import * as yup from "yup";

const getFieldValueByType = (data = [], acctType) => {
  const findType = data?.find(
    (each) =>
      each?.gosprout_account_type && each?.gosprout_account_type === acctType
  );
  return (
    {
      member_group: findType?.member_group ?? null,
      member_group_id: findType?.member_group_id ?? null,
    } || null
  );
};
export const createAccountTypeForm = (data) => {
  return {
    sponsor: getFieldValueByType(data, "Sponsors"),
    employer: getFieldValueByType(data, "Employers"),
    journey_worker: getFieldValueByType(data, "Journey Workers"),
    rti_provider: getFieldValueByType(data, "RTI Providers"),
    apprentice: getFieldValueByType(data, "Apprentices"),
  };
};

const TypeSchema = yup
  .object({
    member_group: yup.string().nullable(),
    member_group_id: yup.number().nullable(),
  })
  .nullable();
export const accountTypeFormSchema = yup.object({
  sponsor: TypeSchema,
  employer: TypeSchema,
  journey_worker: TypeSchema,
  rti_provider: TypeSchema,
  apprentice: TypeSchema,
});
