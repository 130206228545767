import React from "react";
import {
  StyledModalBody,
  StyledModalLeft,
  StyledModalRight,
} from "./IntegrationsAccountTypesDialog.styles";
import Logo from "assets/svgs/gosprout-img.svg";
import { Divider } from "@material-ui/core";
import { LeftContentLoading } from "./LeftContent/LeftContent.loading";
import { RightContentLoading } from "./RightContent/RightContent.loading";

export function IntegrationsAccountTypesDialogLoading() {
  return (
    <StyledModalBody>
      <StyledModalLeft className="invisible-scroll">
        <LeftContentLoading
          logo={Logo}
          title="GoSprout"
          header_description="Connect your neighborhood members to your apprenticeships in GoSprout."
        />
      </StyledModalLeft>
      <Divider orientation="vertical" />
      <StyledModalRight>
        <RightContentLoading />
      </StyledModalRight>
    </StyledModalBody>
  );
}
